import MapPage from "./pages/MapPage";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "CanopyNet";
  }, []);

  return <MapPage />;
}

export default App;

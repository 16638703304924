export function BetaPill() {
  return (
    <p className="text-slate-950 bg-green-500 text-xs rounded-full px-2 py-0 font-semibold">
      beta
    </p>
  );
}

export function InProgressPill() {
  return (
    <p className="text-slate-950 bg-blue-500 text-xs rounded-full px-2 py-0 font-semibold">
      in progress
    </p>
  );
}

export function PlannedPill() {
  return (
    <p className="text-slate-950 bg-purple-500 text-xs rounded-full px-2 py-0 font-semibold">
      planned
    </p>
  );
}

import RadioButtonIcon from "./RadioButtonIcon";
import { BetaPill, InProgressPill, PlannedPill } from "./StatusPill";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function LayerItem(props) {
  return (
    <div className="flex flex-col gap-2 text-slate-100 pr-3">
      <div className="flex flex-row justify-between items-center">
        <div
          className="flex flex-row gap-1 items-center select-none cursor-pointer pl-2"
          onClick={() => props.setSelected(props.layerName)}
        >
          <RadioButtonIcon checked={props.layerName === props.selected} />
          <h1
            className={`${
              props.layerName === props.selected
                ? "text-slate-100 font-semibold"
                : "text-slate-300 font-normal"
            } text-sm`}
          >
            {props.layerName}
          </h1>
        </div>
        {props.selected === props.layerName &&
          (props.available ? (
            props.isLayerVisible ? (
              <EyeIcon
                className="text-slate-400 w-4 cursor-pointer"
                onClick={() => {
                  props.setIsLayerVisible(false);
                  props.toggleLayer();
                }}
              />
            ) : (
              <EyeSlashIcon
                className="text-slate-400 w-4 cursor-pointer"
                onClick={() => {
                  props.setIsLayerVisible(true);
                  props.toggleLayer();
                }}
              />
            )
          ) : (
            <div className="group">
              <EyeSlashIcon className="text-slate-400 w-4 cursor-pointer" />
              <span class="absolute right-4 scale-0 transition-all rounded bg-slate-800 p-2 text-xs text-slate-100 group-hover:scale-100">
                Coming soon!
              </span>
            </div>
          ))}
      </div>
      {props.layerName === props.selected && (
        <div className="flex flex-col gap-3 text-slate-300 text-xs ml-5 border-slate-600 border-l pl-4">
          {props.description}
          <div className="flex flex-row gap-2">
            <p className="font-bold">Status:</p>
            {props.status === "beta" && <BetaPill />}
            {props.status === "inprogress" && <InProgressPill />}
            {props.status === "planned" && <PlannedPill />}
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold">Range:</p>
            <div className=" bg-gradient-to-r from-purple-500 via-blue-500 to-green-500 w-full h-[6px] rounded-full"></div>
            <div className="flex flex-row justify-between">
              <p>
                {props.minValue} {props.units}
              </p>
              <p>
                {props.maxValue} {props.units}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import Sidebar from '../components/Sidebar';
import SwitchComponent from '../components/SwitchComponent';
import MapComponent from '../components/MapComponent';
import PopoverComponent from '../components/PopoverComponent';

function MapPage() {
  return (
    
    <div>
      
      <MapComponent />
    </div>
  );
}

export default MapPage;

import React, { useState } from "react";
import LogoType from "./LogoType";
import LayerGroup from "./LayerGroup";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Sidebar({ isLayerVisible, setIsLayerVisible, toggleLayer }) {
  const items = [
    {
      groupName: "CANOPY STRUCTURE",
      layers: [
        {
          layerName: "Canopy height",
          description:
            "Represents the height of the forest canopy, providing data for understanding forest density, health, and habitat quality. This layer is in public preview at a limited extent.",
          status: "beta",
          minValue: 0,
          maxValue: 30,
          units: "m",
          available: true,
        },
        {
          layerName: "Canopy base height",
          description:
            "Indicates the height at which the forest canopy begins, above the ground. It's an important indicator for fire behavior modeling, as it helps determine the potential for fire to climb from the ground into the canopy.",
          status: "inprogress",
          minValue: 0,
          maxValue: 25,
          units: "m",
          available: false,
        },
        {
          layerName: "Canopy gaps",
          description:
            "Identifies openings within the forest canopy where light reaches the forest floor, important for understanding regeneration potential, biodiversity, and forest dynamics. These gaps are quantified using the relative height method to provide insights into forest structure and health.",
          status: "inprogress",
          minValue: 0,
          maxValue: 1,
          units: "ha",
          available: false,
        },
        {
          layerName: "Canopy bulk density",
          description:
            "Measures the mass of foliage per unit volume of space, providing important data for fire management by estimating the potential heat release in forest fires. It also informs on the capacity of the forest to store carbon.",
          status: "planned",
          minValue: 0,
          maxValue: 1,
          units: "kg/m³",
          available: false,
        },
        {
          layerName: "Vertical stratification",
          description:
            "Describes the vertical layers of the forest canopy, offering insights into the complexity of the habitat structure. This layer also informs fire behavior modeling, as it helps determine the potential for fire to climb from the ground into the canopy.",
          status: "planned",
          minValue: 1,
          maxValue: 5, // Assuming a general classification from ground layer to emergent layer.
          units: "strata",
          available: false,
        },
      ],
    },
    {
      groupName: "CARBON & PRODUCTIVITY",
      layers: [
        {
          layerName: "Above ground biomass",
          description:
            "Amount of dry biomass in kilograms per square meter above the ground.",
          status: "inprogress",
          minValue: 0,
          maxValue: 100,
          units: "kg",
          available: false,
        },
        {
          layerName: "Below ground biomass",
          description:
            "Amount of dry biomass in kilograms per square meter below the ground.",
          status: "inprogress",
          minValue: 0,
          maxValue: 100,
          units: "kg",
          available: false,
        },
        {
          layerName: "Leaf Area Index (LAI)",
          description:
            "Reflects the total leaf area relative to the ground surface area beneath, serving as a indicator of forest productivity, carbon assimilation efficiency, and overall canopy health. LAI is commonly used in models predicting photosynthetic primary production and evapotranspiration.",
          status: "planned",
          minValue: 0,
          maxValue: 10,
          units: "m²/m²",
          available: false,
        },
        {
          layerName: "Net primary productivity",
          description:
            "Amount of carbon in kilograms per square meter per year that is fixed by vegetation.",
          status: "planned",
          minValue: 0,
          maxValue: 3000,
          units: "gC/yr",
          available: false,
        },
      ],
    },
    {
      groupName: "DIVERSITY & COMPOSITION",
      layers: [
        {
          layerName: "Species richness",
          description: "Number of species in the canopy per square meter.",
          status: "planned",
          minValue: 0,
          maxValue: 15,
          units: "",
          available: false,
        },
        {
          layerName: "Species distribution",
          description:
            "Multiband raster with each band representing the relative abundance of a species. This only includes major North American species.",
          status: "planned",
          minValue: 0,
          maxValue: 100,
          units: "%",
          available: false,
        },
        {
          layerName: "Age class",
          description:
            "Age class of trees in the canopy. Ages are binning into 10 year intervals.",
          status: "planned",
          minValue: 0,
          maxValue: 1000,
          units: "yrs",
          available: false,
        },
      ],
    },
    {
      groupName: "WILDLIFE HABITAT",
      layers: [
        {
          layerName: "Habitat Suitability Index",
          description:
            "Provides a composite rating of the forest's ability to support different wildlife species, based on factors such as canopy cover, food availability, and presence of water sources.",
          status: "planned",
          minValue: 0,
          maxValue: 1,
          units: "",
          available: false,
        },
        {
          layerName: "Structural connectivity",
          description:
            "Assesses the physical linkage between habitat patches in the forest canopy, which is essential for the movement and dispersal of wildlife. Connectivity metrics help in the design of conservation strategies and the maintenance of biodiversity.",
          status: "planned",
          minValue: 0,
          maxValue: 1,
          units: "",
          available: false,
        },
        {
          layerName: "Old Growth Structural Index",
          description:
            "Quantifies the structural characteristics of old-growth forests, such as the presence of large trees, snags, and logs, which are critical for various species' habitat requirements and biodiversity.",
          status: "planned",
          minValue: 0,
          maxValue: 1,
          units: "",
          available: false,
        },
      ],
    },
    {
      groupName: "RISK & RESILIENCE",
      layers: [
        {
          layerName: "Fire risk",
          description:
            "Assesses the likelihood of fire events in forested areas, incorporating factors such as vegetation type, historical fire data, and current climate conditions. It aims to help in preventive planning and resource allocation for fire control.",
          status: "planned",
          minValue: 0,
          maxValue: 100,
          units: "%",
          available: false,
        },
        {
          layerName: "Power line risk",
          description:
            "Maps the proximity of vegetation to power lines, highlighting potential risk zones where vegetation management may be needed to prevent power outages or fire hazards due to falling branches or tree growth.",
          status: "planned",
          minValue: 0,
          maxValue: 100,
          units: "%",
          available: false,
        },
        {
          layerName: "Road network risk",
          description:
            "Evaluates the risk to road networks from surrounding vegetation, considering aspects like tree fall potential and the likelihood of obstructed pathways during extreme weather events, assisting in infrastructure risk management.",
          status: "planned",
          minValue: 0,
          maxValue: 100,
          units: "%",
          available: false,
        },
      ],
    },
  ];

  const handleItemClick = (item) => {
    if (item.action) item.action();
  };

  const [selected, setSelected] = useState(items[0].layers[0].layerName);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      {sidebarOpen ? (
        <div className="fixed left-2 top-2 p-4 bg-slate-950/70 z-20 h-[calc(100vh-16px)] rounded-lg flex flex-col w-[calc(100vw-16px)] sm:w-[350px] backdrop-blur-lg border border-slate-950">
          <div className="flex flex-row justify-between items-center">
            <LogoType />
            <XMarkIcon
              className="stroke-slate-300 w-6 cursor-pointer"
              onClick={() => setSidebarOpen(false)}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8 overflow-y-auto">
            {items.map((item) => (
              <LayerGroup
                key={item.groupName}
                groupName={item.groupName}
                layers={item.layers}
                selected={selected}
                setSelected={setSelected}
                isLayerVisible={isLayerVisible}
                setIsLayerVisible={setIsLayerVisible}
                toggleLayer={toggleLayer}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="fixed left-2 top-2 p-4 bg-slate-950/70 z-20 rounded-lg flex flex-col w-[calc(100vw-16px)] sm:w-[350px] backdrop-blur-lg border border-slate-950">
          <div className="flex flex-row justify-between items-center">
            <LogoType />
            <Bars3Icon
              className="stroke-slate-300 w-6 cursor-pointer"
              onClick={() => setSidebarOpen(true)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;

import React from "react";

export default function RadioButtonIcon(props) {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12"
        cy="12"
        r="7"
        stroke="#64748b"
        stroke-width="1"
        fill="none"
      />
      {props.checked && <circle cx="12" cy="12" r="4" fill="#22c55e" />}
    </svg>
  );
}

import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { MinusSmallIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import LayerItem from "./LayerItem";

export default function LayerGroup(props) {
  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex flex-row justify-between items-center hover:bg-slate-900 py-1 px-2 rounded">
            <h1 className="text-slate-400 text-xs font-normal">
              {props.groupName}
            </h1>
            {open ? (
              <MinusSmallIcon className="text-slate-400 w-6" />
            ) : (
              <PlusSmallIcon className="text-slate-400 w-6" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="flex flex-col gap-2 text-slate-100">
            {props.layers.map((layer) => (
              <LayerItem
                key={layer.layerName}
                selected={props.selected}
                setSelected={props.setSelected}
                isLayerVisible={props.isLayerVisible}
                setIsLayerVisible={props.setIsLayerVisible}
                toggleLayer={props.toggleLayer}
                {...layer}
              />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

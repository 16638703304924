import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import Sidebar from "./Sidebar";

// PARAMETERS
// ------------------------------------------------------------
const TILESET_ID = "261A_demo_mosaic";

// ------------------------------------------------------------

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_DEFAULT_KEY;
const MAPBOX_TILE_KEY = process.env.REACT_APP_MAPBOX_TILE_KEY;
// const tileEndPoint = `https://api.mapbox.com/v4/daithimartin.${TILESET_ID}/{z}/{x}/{y}.png?access_token=${MAPBOX_TILE_KEY}`;
const tileEndPoint = "https://storage.googleapis.com/261_test/{z}/{x}/{y}.png"
// const tileEndPoint = "/Users/daithi/PycharmProjects/canopynet/web_app/data/test_tiles/{z}/{x}/{y}.png"

function MapComponent() {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [lng, setLng] = useState(-122.12100563041332);
  const [lat, setLat] = useState(37.100280661621134);
  const [zoom, setZoom] = useState(14);

  const [isLayerVisible, setIsLayerVisible] = useState(true);

  const palatte = ["#440154", "#3b528b", "#21918c", "#5ec962", "#fde725"];

  function toggleLayer() {
    console.log("toggleLayer called");
    const map = mapRef.current;
    if (map && map.getLayer("canopy-height-layer")) {
      const currentVisibility = map.getLayoutProperty(
        "canopy-height-layer",
        "visibility"
      );
      const newVisibility =
        currentVisibility === "visible" ? "none" : "visible";
      map.setLayoutProperty("canopy-height-layer", "visibility", newVisibility);
      setIsLayerVisible(newVisibility === "visible");
    } else {
      console.log("Layer not found or map not initialized");
    }
  }

  useEffect(() => {
    if (!mapContainerRef.current) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [lng, lat],
      zoom: zoom,
      logoPosition: "bottom-left",
      attributionControl: false,
    });

    mapRef.current = map;

    map.addControl(
      new mapboxgl.AttributionControl({
        customAttribution: "© Silvx Labs",
      }),
      "bottom-right"
    );

    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");

    // map.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.on("move", () => {
      setLng(parseFloat(map.getCenter().lng.toFixed(4)));
      setLat(parseFloat(map.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(map.getZoom().toFixed(2)));
    });

    map.on("load", function () {
      // Define a custom raster source referencing your Mapbox tileset
      map.addSource("canopy-height-layer", {
        type: "raster", // Specify 'raster' as the source type
        tiles: [tileEndPoint], // URL to your custom tileset
        "scheme": "tms",
        tileSize: 256, // Adjust tileSize as needed
        "minzoom": 0,
        "maxzoom": 17,
      });

      // Define the layer's style based on a color ramp
      map.addLayer({
        id: "canopy-height-layer",
        type: "raster",
        source: "canopy-height-layer",
        minzoom: 0,
        maxzoom: 17,
        layout: {
          visibility: isLayerVisible ? "visible" : "none", // set the initial visibility
        },
        paint: {
          "raster-color": [
            "interpolate",
            ["linear"],
            ["raster-value"],
            1,
            palatte[0],
            50,
            palatte[1],
            100,
            palatte[2],
            150,
            palatte[3],
            244,
            palatte[4],
          ],
          "raster-color-mix": [255, 0, 0, 0],
          "raster-color-range": [1.0, 244.0],
          "raster-opacity": 1,
        },
      });
    });

    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="h-screen w-screen">
      <Sidebar
        isLayerVisible={isLayerVisible}
        setIsLayerVisible={setIsLayerVisible}
        toggleLayer={toggleLayer}
      />
      <div
        className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden"
        ref={mapContainerRef}
      />
    </div>
  );
}

export default MapComponent;
